import { CloseOutlined } from "@ant-design/icons";
import axios from "axios";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import styles from "./AiPopup.module.scss";
import { message } from "antd";

const schema = Yup.object().shape({
  email: Yup.string()
    .matches(/^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i, {
      message: "Invalid email",
    })
    .required("Email is required"),
  message: Yup.string(),
});

const PopupSuccess = ({ onClose, setEmailSent }) => {
  const [counterStarted, setCounterStarted] = React.useState(false);
  React.useEffect(() => {
    if (!counterStarted) {
      setCounterStarted(true);
    } else {
      setTimeout(() => {
        setEmailSent(false);
        onClose();
      }, 3000);
    }
  }, [counterStarted]);

  return (
    <>
      <h4 className={styles.title}>Thank you for you message!</h4>
      <p className={styles.subtitle}>We will contact you shortly</p>
    </>
  );
};

const AiPopup = ({ show, onClose }) => {
  const [emailSent, setEmailSent] = React.useState(false);

  const handleSubmit = async (values) => {
    const failMessage =
      "Email submission failed! Please try again in a few instants";

    try {
      const res = await axios.post(
        process.env.REACT_APP_SEND_EMAIL_URL,
        JSON.stringify({
          subject: "Generative AI Banner",
          text: `${values?.email}: ${values?.message}`,
        }),
        { headers: { "Content-Type": "application/json" } }
      );
      if (res.status == 200) {
        setEmailSent(true);
      } else {
        message.error(failMessage);
      }
    } catch (err) {
      message.error(failMessage);

      return console.error(err);
    }
  };

  if (!show) {
    return null;
  } else {
    return (
      <div className={styles.popupContainer}>
        <span className={styles.background} onClick={onClose} />
        <div
          className={`${styles.popup} ${emailSent ? styles.smallPopup : ""}`}
        >
          {emailSent ? (
            <PopupSuccess onClose={onClose} setEmailSent={setEmailSent} />
          ) : (
            <>
              <h4 className={styles.title}>
                Interested in our Generative AI Adoption Framework?
              </h4>

              <h4 className={styles.subtitle}>
                Or want to talk to us about anything else?
              </h4>

              <button
                type="button"
                onClick={onClose}
                className={styles.closeButton}
              >
                <CloseOutlined className={styles.closeIcon} />
              </button>

              <Formik
                initialValues={{ email: "", message: "" }}
                validationSchema={schema}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                {({
                  errors,
                  touched,
                  values,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  submitting,
                }) => {
                  return (
                    <Form className={styles.form}>
                      <p>
                        <input
                          className={`${styles.emailInput} ${
                            errors.email && styles.error
                          }`}
                          id="email"
                          name="email"
                          placeholder="Email (Required)"
                          required
                          type="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <em>{errors?.email}</em>
                      </p>

                      <textarea
                        className={styles.textArea}
                        id="message"
                        name="message"
                        placeholder="Message"
                        type="text"
                        maxLength="500"
                        value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <button
                        type="submit"
                        className={`${styles.submitButton}`}
                        onClick={handleSubmit}
                        disabled={
                          submitting ||
                          Object.keys(errors).length > 0 ||
                          values?.email.trim() === ""
                        }
                      >
                        Send message
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </>
          )}
        </div>
      </div>
    );
  }
};

export default AiPopup;
