import componentsImage from "./components.png";
import originalLogo from "./original-pyyne-logo.png";
import DmCase from "./DmCase.png";
import InveterateCase from "./InveterateCase.png";
import LandscapehubCase from "./LandscapehubCase.png";
import LyftaCase from "./LyftaCase.png";
import PackCase from "./PackCase.png";
import TMGCase from "./TMGCase.png";

import logoArtfare from "./logo-artfare.png";
import logoDynamicMining from "./logo-dynamic-mining.png";
import logoFabric from "./logo-fabric.png";
import logoInveterate from "./logo-inveterate.png";
import logoInveterate2 from "./logo-inveterate-2.png";
import logoLyfta from "./logo-lyfta.png";
import logoPackDigital from "./logo-pack-digital.png";
import logoRever from "./logo-rever.png";
import logoSas from "./logo-sas.png";
import logoSpotify from "./logo-spotify.png";
import logoStarwood from "./logo-starwood.png";
import logoTibber from "./logo-tibber.png";
import logoTmg from "./logo-tmg.png";
import logoTmg2 from "./logo-tmg-2.png";
import logoVetfamily from "./logo-vetfamily.png";

export const ImgComponents = () => (
  <img src={componentsImage} alt="work image" />
);

export const OriginalLogo = () => (
  <img src={originalLogo} alt="logo original" />
);

export const ImgDmCase = () => <img src={DmCase} alt="case dmCase" />;

export const ImgInveterateCase = () => (
  <img src={InveterateCase} alt="case inveterateCase" />
);

export const ImgLandscapehubCase = () => (
  <img src={LandscapehubCase} alt="case landscapehubCase" />
);

export const ImgLyftaCase = () => <img src={LyftaCase} alt="case lyftaCase" />;

export const ImgPackCase = () => <img src={PackCase} alt="case packCase" />;

export const ImgTMGCase = () => <img src={TMGCase} alt="case tmgCase" />;

export const ImgLogoArtfare = () => (
  <img src={logoArtfare} alt="logo cliente" />
);

export const ImgLogoDynamicMining = () => (
  <img src={logoDynamicMining} alt="logo cliente" />
);

export const ImgLogoFabric = () => <img src={logoFabric} alt="logo cliente" />;

export const ImgLogoInveterate = () => (
  <img src={logoInveterate} alt="logo cliente" />
);

export const ImgLogoInveterate2 = () => (
  <img src={logoInveterate2} alt="logo cliente" />
);

export const ImgLogoLyfta = () => <img src={logoLyfta} alt="logo cliente" />;

export const ImgLogoPackDigital = () => (
  <img src={logoPackDigital} alt="logo cliente" />
);

export const ImgLogoRever = () => <img src={logoRever} alt="logo cliente" />;

export const ImgLogoSas = () => <img src={logoSas} alt="logo cliente" />;

export const ImgLogoSpotify = () => (
  <img src={logoSpotify} alt="logo cliente" />
);

export const ImgLogoStarwood = () => (
  <img src={logoStarwood} alt="logo cliente" />
);

export const ImgLogoTibber = () => <img src={logoTibber} alt="logo cliente" />;

export const ImgLogoTmg = () => <img src={logoTmg} alt="logo cliente" />;

export const ImgLogoTmg2 = () => <img src={logoTmg2} alt="logo cliente" />;

export const ImgVetFamily = () => (
  <img src={logoVetfamily} alt="logo cliente" />
);
