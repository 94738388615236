import styles from "./TextSideAnimator.module.scss";

export const TextSideAnimator = ({
  children,
  className,
  style,
  textColor,
  focusColor,
  noUnderline,
  hoverUnderline,
}) => {
  return (
    <span
      className={`${styles.sideAnimator} ${className ? className : ""}`}
      style={{
        ...style,
        "--fontColor": textColor,
        "--highlightColor": focusColor,
        "--underlineMultiplier": noUnderline ? 0 : 1,
        "--hoverUnderline": hoverUnderline ? 1 : noUnderline ? 0 : 1,
      }}
    >
      {children}
    </span>
  );
};
