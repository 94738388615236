import { Link } from "react-router-dom";
import { TextSideAnimator } from "./TextSideAnimator";

export const NavLinks = ({ className, style, onClick, underline }) => {
  const handleClick = (event) => {
    onClick(event);
  };
  return (
    <nav className={className} style={style} onClick={handleClick}>
      {/* <Link to="/clients">Clients</Link> */}
      <TextSideAnimator noUnderline={!underline}>
        <Link to="/about">About</Link>
      </TextSideAnimator>

      {/*
      <TextSideAnimator noUnderline={!underline}>
        <Link to="/work">Work</Link>
      </TextSideAnimator>
      */}
    </nav>
  );
};
