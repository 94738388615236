import React from "react";
import { useIsInsideView } from "../shared/hooks";
import { ContentSoftLoad } from "./ContentSoftLoad";
import styles from "./PyySection.module.scss";

export const PyySection = ({
  className,
  style,
  children,
  animateFirstLevel,
  bannerMode = false,
  dualMode = false,
  fullWidth = false,
  sectionTitle,
  softLoad = false,
  softLoadThreshold = 0.25,
  loadAnimationType = "fade",
  animationTime = "300ms",
  innerWidth,
}) => {
  const slotRef = React.useRef();
  const { isInsideView, isBelowView } = useIsInsideView(slotRef, {
    threshold: softLoadThreshold,
  });
  const triggerHide = softLoad && !isInsideView && isBelowView;

  return (
    <div
      className={`${styles.pyySection} ${className || ""} ${
        animateFirstLevel ? styles.animated : ""
      } ${bannerMode ? styles.bannerMode : ""} ${
        dualMode && !bannerMode ? styles.dualMode : ""
      } ${fullWidth ? styles.fullWidth : ""} ${
        triggerHide ? styles.hideRender : ""
      } ${
        triggerHide && loadAnimationType === "grow" ? styles.reduceChildren : ""
      }`}
      style={style}
      ref={slotRef}
    >
      {sectionTitle && <div className={styles.sectionTitle}>{sectionTitle}</div>}
      <ContentSoftLoad
        noAnimation={!softLoad}
        loadThreshold={softLoadThreshold}
        animationType={loadAnimationType}
        animationTime={animationTime}
        width={innerWidth}
      >
        {children}
      </ContentSoftLoad>
    </div>
  );
};
