import { CloseOutlined } from "@ant-design/icons";
import React from "react";
import { useRecoilState } from "recoil";
import { bannerShowState, popUpOpenState } from "../shared/state";
import styles from "./AiBanner.module.scss";
import AiPopup from "./AiPopup";

function AiBanner() {
  const [popUpOpen, setPopupOpen] = useRecoilState(popUpOpenState);
  const [bannerShown, setBannerShow] = useRecoilState(bannerShowState);

  const handlePopup = () => {
    setPopupOpen(!popUpOpen);
  };

  const handleBannerShow = () => {
    setBannerShow(!bannerShown);
  };

  if (bannerShown) {
    return (
      <>
        <div className={styles.banner}>
          <button className={styles.text} onClick={handlePopup} type="button">
            Learn more about our Generative AI Adoption Framework!
          </button>

          <button
            type="button"
            onClick={handleBannerShow}
            className={styles.closeButton}
          >
            <CloseOutlined className={styles.closeIcon} />
          </button>
        </div>

        <AiPopup show={popUpOpen} onClose={handlePopup} />
      </>
    );
  } else {
    return null;
  }
}

export default AiBanner;
