import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { showMenuState } from "../shared/state";
import { NavLinks } from "./NavLinks";

import styles from "./NavMenu.module.scss";
import { TextSideAnimator } from "./TextSideAnimator";

export const NavMenu = () => {
  const [showMenu, setShowMenu] = useRecoilState(showMenuState);

  const handleClick = () => {
    setShowMenu(!showMenu);
  };
  return (
    <section
      className={
        showMenu ? `${styles.navMenu} ${styles.showing}` : styles.navMenu
      }
    >
      <button
        type="button"
        onClick={handleClick}
        className={showMenu ? styles.closeMode : ""}
      >
        <span />
        <span />
      </button>

      <div>
        <NavLinks onClick={handleClick} />

        <div>
          <p>
            <b>Get in touch</b>
          </p>
          <p>
            <TextSideAnimator
              textColor="var(--colorWhiteDark)"
              focusColor="var(--colorSecondaryAltDark)"
            >
              <a href="mailto:hello@pyyne.com">hello@pyyne.com</a>
            </TextSideAnimator>
          </p>
        </div>
      </div>
    </section>
  );
};
