import React from "react";
import { useRecoilValue } from "recoil";
import {
  ClientsBanner,
  ContentSoftLoad,
  Dropdown,
  ProjectCard,
  PyyCarousel,
  PyySection,
  PyyneLogo,
  TextQueue,
  TextShowAnimator,
  TextSideAnimator,
} from "../components";
import { totalClientsShowState } from "../shared/state";

import { useCasesList } from "../shared/hooks";
import styles from "./home.module.scss";

const QUOTES = [
  {
    text: "Since partnering with PYYNE, we’ve been able to easily integrate top tier, specialized engineering talent into our existing development team. This is a world class group of operators and have approached our needs expertly, tactically, and empathetically.",
    author: "Andy Muntean",
    role: "Head of Product, Inveterate",
  },
  {
    text: "From the very beginning, PYYNE demonstrated a deep understanding of our business and its unique challenges. They have worked closely with our team to develop a scalable solution for our engineering team and customers. PYYNE is highly collaborative and responsive and a true extension of our team.",
    author: "Cory Cummings",
    role: "Co-Founder & CEO, Pack Digital",
  },
  {
    text: "PYYNE supports multiple Mu Ventures portfolio companies, and the founder feedback has been stellar. They are my value-add “secret” weapon!",
    author: "Gary Benerofe",
    role: "GP, Mu Ventures",
  },
  {
    text: "We had a great experience with PYYNE. They have a wealth of experience in technology and growth and it was really helpful to be able to tap into that as we evolved from a small SaaS startup to a scaleup.",
    author: "Serdar Ferit",
    role: "Co-Founder & CEO, Lyfta",
  },
];

const PYYNE_EVENTS = [
  {
    date: "June 6",
    description: "PYYNE Digital attends Entrepreneurs Roundtable in NYC",
  },
  {
    date: "June 8",
    description: "PYYNE Digital attends Venture Community Mixer in NYC",
  },
  {
    date: "June 26-29",
    description: "PYYNE Digital attends Collision in Toronto",
  },
];

const FAQ = [
  {
    title: "What languages do you work with?",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae pulvinar libero, vel tempor dolor. Nunc fringilla sed lectus vel suscipit. Cras tempus lorem at ipsum fringilla, vitae maximus turpis sagittis. Ut quis massa egestas, luctus tellus at, imperdiet mi. Ut semper ligula at convallis feugiat. Pellentesque bibendum interdum venenatis. Cras eget massa ut odio eleifend porttitor. Morbi molestie, mi ut lacinia sagittis, metus dolor maximus elit, non elementum tortor elit nec orci. Donec bibendum augue egestas purus dignissim pulvinar. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
  },
  {
    title: "What cloud platforms do you specialize in?",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae pulvinar libero, vel tempor dolor. Nunc fringilla sed lectus vel suscipit. Cras tempus lorem at ipsum fringilla, vitae maximus turpis sagittis. Ut quis massa egestas, luctus tellus at, imperdiet mi. Ut semper ligula at convallis feugiat. Pellentesque bibendum interdum venenatis. Cras eget massa ut odio eleifend porttitor. Morbi molestie, mi ut lacinia sagittis, metus dolor maximus elit, non elementum tortor elit nec orci. Donec bibendum augue egestas purus dignissim pulvinar. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
  },
  {
    title: "Do you have experience working with ChatGPT?",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae pulvinar libero, vel tempor dolor. Nunc fringilla sed lectus vel suscipit. Cras tempus lorem at ipsum fringilla, vitae maximus turpis sagittis. Ut quis massa egestas, luctus tellus at, imperdiet mi. Ut semper ligula at convallis feugiat. Pellentesque bibendum interdum venenatis. Cras eget massa ut odio eleifend porttitor. Morbi molestie, mi ut lacinia sagittis, metus dolor maximus elit, non elementum tortor elit nec orci. Donec bibendum augue egestas purus dignissim pulvinar. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
  },
  {
    title: "How exactly do you deliver projects?",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae pulvinar libero, vel tempor dolor. Nunc fringilla sed lectus vel suscipit. Cras tempus lorem at ipsum fringilla, vitae maximus turpis sagittis. Ut quis massa egestas, luctus tellus at, imperdiet mi. Ut semper ligula at convallis feugiat. Pellentesque bibendum interdum venenatis. Cras eget massa ut odio eleifend porttitor. Morbi molestie, mi ut lacinia sagittis, metus dolor maximus elit, non elementum tortor elit nec orci. Donec bibendum augue egestas purus dignissim pulvinar. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
  },
  {
    title: "Lorem ispum",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae pulvinar libero, vel tempor dolor. Nunc fringilla sed lectus vel suscipit. Cras tempus lorem at ipsum fringilla, vitae maximus turpis sagittis. Ut quis massa egestas, luctus tellus at, imperdiet mi. Ut semper ligula at convallis feugiat. Pellentesque bibendum interdum venenatis. Cras eget massa ut odio eleifend porttitor. Morbi molestie, mi ut lacinia sagittis, metus dolor maximus elit, non elementum tortor elit nec orci. Donec bibendum augue egestas purus dignissim pulvinar. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
  },

  {
    title: "Lorem ispum",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae pulvinar libero, vel tempor dolor. Nunc fringilla sed lectus vel suscipit. Cras tempus lorem at ipsum fringilla, vitae maximus turpis sagittis. Ut quis massa egestas, luctus tellus at, imperdiet mi. Ut semper ligula at convallis feugiat. Pellentesque bibendum interdum venenatis. Cras eget massa ut odio eleifend porttitor. Morbi molestie, mi ut lacinia sagittis, metus dolor maximus elit, non elementum tortor elit nec orci. Donec bibendum augue egestas purus dignissim pulvinar. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
  },

  {
    title: "Lorem ispum",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae pulvinar libero, vel tempor dolor. Nunc fringilla sed lectus vel suscipit. Cras tempus lorem at ipsum fringilla, vitae maximus turpis sagittis. Ut quis massa egestas, luctus tellus at, imperdiet mi. Ut semper ligula at convallis feugiat. Pellentesque bibendum interdum venenatis. Cras eget massa ut odio eleifend porttitor. Morbi molestie, mi ut lacinia sagittis, metus dolor maximus elit, non elementum tortor elit nec orci. Donec bibendum augue egestas purus dignissim pulvinar. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
  },
];

export const Home = () => {
  const totalClientsShow = useRecoilValue(totalClientsShowState);
  const logoFadeOutDelay = 3500;
  const { preparedCasesList, projectCardWidth, dualColProjectCardWidth } =
    useCasesList({ onlyFeatured: true, maxItems: 4 });

  return (
    <>
      <PyySection className={styles.firstText}>
        {/* <ContentSoftLoad anchorMultiplier={0} className={styles.firstText}> */}
        <h3>
          Tech Enablement Firm for Startups and Scaleups, based in&nbsp;
          <b>
            {/* <TextShowAnimator
              textList={["New York", "São Paulo", "Stockholm"]}
            /> */}
            <TextQueue
              textList={["New York", "São Paulo", "Stockholm"]}
              waitTime={2000}
            />
          </b>
        </h3>
        {/* </ContentSoftLoad> */}
      </PyySection>

      <PyySection
        bannerMode
        className={styles.logoContainer}
        style={{ "--fadeoutDelay": `${logoFadeOutDelay}ms` }}
      >
        <span>
          <PyyneLogo size="25vmin" />
        </span>

        {/* <span>
          <TextShowAnimator
            textList={["HELLO THERE"]}
            startDelay={logoFadeOutDelay}
            keepFirstText
            noInfiniteLoop
            justify="center"
            customFirstContainerAnimation={{
              scale: [1, 0.3],
              translateY: [0, -100],
            }}
          />
        </span>

        <span>
          <TextShowAnimator
            textList={[
              "Fiercely committed to our client's success",
              "We build technology for startups that are changing how the world works",
              "With immense respect for the engineering craft",
              "We code, deploy and run software for scaling businesses",
              "Always in close collaboration with our clients",
              "Get in touch to learn more!",
            ]}
            justify="center"
            startDelay={logoFadeOutDelay + 1500}
          />
        </span> */}
      </PyySection>

      <PyySection
        dualMode
        className={styles.introTexts}
        style={{ alignItems: "flex-start" }}
        softLoad
      >
        <p className={styles.descriptionText}>
          <b>
            We engineer world-class digital products for scaling businesses.
          </b>
        </p>

        <div className={styles.dropdownContainer}>
          <Dropdown title="Software Engineering">
            <p>
              We love code and take pride in what we do. We partner with digital
              leaders to support and accelerate the creation of digital products
              and services, from the design and architecting stages, through to
              implementation and operation.
            </p>

            <p>
              We are cloud-first, agile, and rest our deliverables on
              exceptional technical competence.
            </p>
          </Dropdown>

          <Dropdown title="Cloud & DevOps">
            <p>
              We help our clients maximize the benefits of the cloud; we design,
              architect, and configure secure and scalable cloud solutions.
            </p>

            <p>
              Through improved processes, tooling, and increased automation we
              empower teams to ship new features faster and more securely.
            </p>
          </Dropdown>

          <Dropdown title="Data & AI">
            <p>
              We unlock the power of data for our clients, facilitating their
              transformation into data and AI-capable organizations. We help
              clients with everything from conducting smaller data experiments
              to building full-blown data organizations.
            </p>

            <p>
              We leverage the latest tools and technologies to build
              sophisticated cloud data platforms that provide answers to the
              right business questions.
            </p>
          </Dropdown>

          <Dropdown title="Strategy & Advisory">
            <p>
              Our strategy and advisory team supports our partners through
              periods of fast-paced growth and helps them lay the groundwork for
              future-proof, scalable organizations capable of dealing with the
              ever-changing technology landscape.
            </p>

            <p>
              This, in turn, allows our clients to effectively service their
              customers, providing them with renewed and relevant products and
              services. Our clients love that we are small and nimble but with a
              global reach.
            </p>
          </Dropdown>
        </div>
      </PyySection>

      <PyySection className={styles.clientsContainer} softLoad>
        <ClientsBanner numActiveItems={totalClientsShow} />
      </PyySection>

      {preparedCasesList.map((caseRow, rowIndex, rowList) => (
        <PyySection
          key={`row${rowIndex}`}
          dualMode
          className={styles.projectCardsContainer}
          sectionTitle={rowIndex === 0 && <>All Client Cases</>}
        >
          {caseRow.map((clientCase, index, clientList) => (
            <ContentSoftLoad
              key={`row${rowIndex}.client${index}`}
              animationType="grow"
            >
              <ProjectCard
                {...clientCase}
                width={
                  rowIndex === rowList.length - 1 && clientList.length === 1
                    ? projectCardWidth
                    : dualColProjectCardWidth
                }
              />
            </ContentSoftLoad>
          ))}
        </PyySection>
      ))}

      <PyySection fullWidth className={styles.quoteContainer} softLoad>
        <PyyCarousel
          itemList={QUOTES.map((quote, index) => (
            <span key={index} className={styles.testimonialText}>
              <p>{`"${quote.text}"`}</p>
              <span>
                <p>{quote.author}</p>
                <p>{quote.role}</p>
              </span>
            </span>
          ))}
          infiniteMode
          fadeTransition
          hideArrows
        />
      </PyySection>
      {/* </ContentSoftLoad> */}

      {/* <ContentSoftLoad
        className={styles.fullWidthChild}
        anchorMultiplier={mobileMode ? 2.2 : 2}
      > */}
      <PyySection className={styles.timelineContainer} softLoad>
        {PYYNE_EVENTS.map((event, index) => (
          <PyySection key={index} dualMode>
            <p>{event.date}</p>
            <h5>{event.description}</h5>
          </PyySection>
        ))}
      </PyySection>
      {/* </ContentSoftLoad> */}

      <PyySection
        fullWidth
        className={styles.faqContainer}
        style={{ display: "none" }}
        softLoad
      >
        <header>FAQ</header>
        {FAQ.map((item, index) => (
          <Dropdown key={index} title={item.title}>
            <p>{item.description}</p>
          </Dropdown>
        ))}
      </PyySection>
    </>
  );
};
