import React from "react";
import styles from "./TextQueue.module.scss";

export const TextQueue = ({ textList = [], waitTime = 1000 }) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useEffect(() => {
    const currentDOM = document.getElementById(`txt${currentIndex}`);

    currentDOM.classList.add(styles.showText);
  }, []);

  React.useEffect(() => {
    if (textList.length > 1) {
      setTimeout(() => {
        const previousIndex =
          currentIndex === 0 ? textList.length - 1 : currentIndex - 1;
        const nextIndex =
          currentIndex === textList.length - 1 ? 0 : currentIndex + 1;
        const previousDOM = document.getElementById(`txt${previousIndex}`);
        const currentDOM = document.getElementById(`txt${currentIndex}`);
        const nextDOM = document.getElementById(`txt${nextIndex}`);

        previousDOM?.classList?.remove(styles.showText);
        previousDOM?.classList?.add(styles.fadeAway);
        currentDOM?.classList?.add(styles.showText);
        nextDOM?.classList?.remove(styles.fadeAway);

        setCurrentIndex(nextIndex);
      }, waitTime);
    }
  }, [currentIndex]);

  return (
    <span className={styles.textQueue}>
      {textList.map((text, index) => (
        <span id={`txt${index}`} key={index}>
          {text}
        </span>
      ))}
    </span>
  );
};
