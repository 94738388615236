import React from "react";
import { useRecoilValue } from "recoil";
import { useIsInsideView } from "../shared/hooks";
import { mobileModeState, scrollTopState } from "../shared/state";
import styles from "./ContentSoftLoad.module.scss";

export const ContentSoftLoad = ({
  children,
  style,
  className,
  loadThreshold = 0.25,
  animationType = "fade",
  animationTime = "300ms",
  width,
  noAnimation = false,
  debugMode = false,
}) => {
  const slotRef = React.useRef();
  const { isInsideView, isBelowView } = useIsInsideView(
    slotRef,
    {
      threshold: loadThreshold,
    },
    debugMode
  );
  const triggerHide = !noAnimation && !isInsideView && isBelowView;

  return (
    <span
      className={`${styles.contentSoftLoad} ${
        triggerHide ? styles.hideContent : ""
      } ${
        triggerHide && animationType.toLowerCase() === "grow"
          ? styles.reduceContent
          : ""
      } ${className || ""}`}
      style={{
        ...style,
        "--contentAnimationTime": noAnimation ? "0s" : animationTime,
        "--containerWidth": width,
      }}
      ref={slotRef}
    >
      {children}
    </span>
  );
};
