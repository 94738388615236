import React from "react";

export const useIsInsideView = (ref, options, debugMode) => {
  const [isInsideView, setIsInsideView] = React.useState(false);
  const [isBelowView, setIsBelowView] = React.useState(true);

  options = options || {
    threshold: 0.25,
  };

  const updateFlags = (entry) => {
    setIsInsideView(entry.isIntersecting);
    setIsBelowView(entry.boundingClientRect.top > 0);
  };

  const observer = React.useMemo(() => {
    return new IntersectionObserver(([entry]) => {
      if (debugMode) {
        console.log("entry", entry);
      }
      return updateFlags(entry);
    }, options);
  }, [options]);

  React.useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return { isInsideView, isBelowView };
};
