import { UserOutlined, UnorderedListOutlined } from "@ant-design/icons";

import styles from "./ProjectCard.module.scss";

export const ProjectCard = ({
  ImgComponent,
  imgName,
  usedStack = [],
  teamType,
  skillsList = [],
  officesList = [],
  title,
  subtitle,
  timeBetweenStages,
  width = "18rem",
  height,
  scale = 1,
  maxStackNumber = 10,
  maxSkillsNumber = 3,
  imageExtension = "png",
}) => {
  return (
    <span
      className={styles.workCard}
      style={{
        "--timeBetweenStages": timeBetweenStages || "1s",
        "--initialScale": scale,
        "--initialWidth": width,
        "--initialHeight": height,
      }}
    >
      <span className={styles.imageContainer}>
        {ImgComponent ? (
          <ImgComponent />
        ) : imgName ? (
          <img
            alt={`graphic${title}`}
            src={require(`../assets/images/${imgName}.${imageExtension}`)}
          />
        ) : (
          <span className={styles.imgPlaceholder}>IMAGE</span>
        )}

        <span className={styles.secondStage}>
          <span className={styles.background} />
          <h5 className={styles.description}>
            <b>{teamType}</b>
            &nbsp;
            <ul>
              (
              {officesList?.map((office, index, array) => (
                <li key={index}>
                  {array.length === 0 || index === array.length - 1 ? (
                    office
                  ) : index < array.length - 2 ? (
                    <>{office},&nbsp;</>
                  ) : (
                    <>{office} &&nbsp;</>
                  )}
                </li>
              ))}
              )
            </ul>
          </h5>

          <ul className={styles.skillsList}>
            <UserOutlined />
            {skillsList
              ?.slice(0, maxSkillsNumber)
              ?.map((skill, index, array) => (
                <li key={index}>
                  {array.length === 0 || index === array.length - 1 ? (
                    skill
                  ) : index < array.length - 2 ? (
                    <>{skill},&nbsp;</>
                  ) : (
                    <>{skill} &&nbsp;</>
                  )}
                </li>
              ))}
            {skillsList.length > maxSkillsNumber && (
              <li>{`+ ${skillsList.length - maxSkillsNumber}`}</li>
            )}
          </ul>

          <ul className={styles.usedStack}>
            <UnorderedListOutlined />
            {usedStack?.slice(0, maxStackNumber)?.map((stack, index, array) => (
              <li key={index}>
                {array.length === 0 || index === array.length - 1 ? (
                  stack
                ) : index < array.length - 2 ? (
                  <>{stack},&nbsp;</>
                ) : (
                  <>{stack} &&nbsp;</>
                )}
              </li>
            ))}
            {usedStack.length > maxStackNumber && (
              <li>{`+${usedStack.length - maxStackNumber}`}</li>
            )}
          </ul>
        </span>
      </span>

      <span className={styles.basicInfo}>
        <h5>{title}</h5>
        <p>{subtitle}</p>
      </span>
    </span>
  );
};
