import React from "react";
import styles from "./Dropdown.module.scss";

export const Dropdown = ({ title, children, style, className }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div
      className={`${styles.dropDown} ${className || ""} ${
        isOpen ? styles.open : ""
      }`}
      style={{ ...style }}
    >
      <h5 onClick={() => setIsOpen(!isOpen)}>
        <span>{title}</span> <span>&#8250;</span>
      </h5>

      <div>{children}</div>
    </div>
  );
};
