import React from "react";

import { ConfigProvider } from "antd";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { NavLinks, PyySection, TextSideAnimator } from "./components";
import {
  bannerShowState,
  mobileModeState,
  scrollTopState,
  themeState,
  totalClientsShowState,
} from "./shared/state";

import { useLocation } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import styles from "./App.module.scss";
import Header from "./components/Header";
import { saveSessionStorage } from "./helpers";
import { Routes } from "./pages/routes";
import { DESKTOP_THRESHOLD } from "./shared/constants";

export default function App() {
  const theme = useRecoilValue(themeState);
  // const visitedBefore = getSessionStorage("visitedBefore");
  const [scrollTop, setScrollTop] = useRecoilState(scrollTopState);
  const setTotalClientsShow = useSetRecoilState(totalClientsShowState);
  const setMobileMode = useSetRecoilState(mobileModeState);
  // const mainRef = React.useRef();
  const logoChangeThreshold = 130;
  const { pathname } = useLocation();
  const mobileMode = useRecoilValue(mobileModeState);
  const bannerShown = useRecoilValue(bannerShowState);

  const handleScroll = () => {
    const _scrollY = Math.floor(window.scrollY);

    setScrollTop(_scrollY);
  };

  const handleResize = () => {
    const { innerWidth } = window;
    const isMobile = innerWidth < DESKTOP_THRESHOLD;

    setTotalClientsShow(isMobile ? 9 : 10);
    setMobileMode(isMobile);
  };

  React.useEffect(() => {
    saveSessionStorage("visitedBefore", true);

    handleResize();
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div
      className={`${theme}
        ${styles.baseContainer}
        ${styles.Header} 
        ${bannerShown ? styles.HeaderTopBanner : styles.HeaderTop}`}
    >
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#85ac6c",
          },
        }}
      >
        <Header
          scrollTop={scrollTop}
          logoChangeThreshold={logoChangeThreshold}
          mobileMode={mobileMode}
        />

        <main>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <Routes />
        </main>

        <footer>
          <PyySection>
            <div>
              <h2>Let's talk.</h2>

              <h4>
                <TextSideAnimator textColor="var(--colorWhiteDark">
                  <a href="mailto:hello@pyyne.com">hello@pyyne.com</a>
                </TextSideAnimator>
              </h4>
            </div>

            <div>
              <NavLinks className={styles.secondaryNav} underline />

              <nav className={styles.socialContainer}>
                <TextSideAnimator textColor="var(--colorWhiteDark)">
                  <a
                    target="_blank"
                    rel="Linkedin url"
                    href="https://www.linkedin.com/company/pyyne-digital/"
                  >
                    LinkedIn
                  </a>
                </TextSideAnimator>

                <TextSideAnimator textColor="var(--colorWhiteDark)">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/pyynedigital/?hl=en"
                  >
                    Instagram
                  </a>
                </TextSideAnimator>

                <TextSideAnimator textColor="var(--colorWhiteDark)">
                  <a target="_blank" href="https://twitter.com/pyynedigital">
                    Twitter
                  </a>
                </TextSideAnimator>
              </nav>
            </div>
          </PyySection>
        </footer>
      </ConfigProvider>
    </div>
  );
}
