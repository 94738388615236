import React from "react";

import styles from "./PyyCarousel.module.scss";

export const PyyCarousel = ({
  className,
  style,
  autoTransition = false,
  showControls = true,
  infiniteMode = false,
  hideDots = false,
  itemList = [],
  animationTime = 500,
  animationDelayTime,
  fadeTransition = false,
  intervalTime = 3000,
  hideArrows = false,
}) => {
  animationDelayTime = animationDelayTime
    ? animationDelayTime
    : fadeTransition
      ? 500
      : 0;
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [prevIndex, setPrevIndex] = React.useState(0);
  const [nextIndex, setNextIndex] = React.useState(0);
  const prevItemRef = React.useRef();
  const currentItemRef = React.useRef();
  const nextItemRef = React.useRef();
  const [isAnimating, setIsAnimating] = React.useState(false);
  const [autoMode, setAutoMode] = React.useState(false);

  const fixPositions = (targetIndex) => {
    const { current: currentItemElement } = currentItemRef;
    const { current: nextItemElement } = nextItemRef;

    setCurrentIndex(targetIndex);
    if (fadeTransition) {
      currentItemElement.classList.remove("hidden");
      // nextItemElement.classList.remove(styles.transitioning);
      // nextItemElement.classList.add("hidden");
    }

    setIsAnimating(false);
  };

  const handleFadeTransition = (targetIndex) => {
    const { current: currentItemElement } = currentItemRef;
    const { current: nextItemElement } = nextItemRef;

    setSelectedIndex(targetIndex);
    currentItemElement.classList.add("hidden");
    // nextItemElement.classList.add(styles.transitioning);
    // nextItemElement.classList.remove("hidden");

    setTimeout(() => {
      fixPositions(targetIndex);
    }, animationTime + animationDelayTime);
  };

  const callIndex = (targetIndex, calledByButton) => {
    if (!isAnimating) {
      setIsAnimating(true);
      if (fadeTransition) {
        setNextIndex(targetIndex);
        handleFadeTransition(targetIndex);
      }

      if (calledByButton) {
        setAutoMode(false);
      }
    }
  };

  React.useEffect(() => {
    setAutoMode(autoTransition);
  }, []);

  React.useEffect(() => {
    const _nextIndex =
      currentIndex < itemList.length - 1 ? currentIndex + 1 : 0;
    const _prevIndex =
      currentIndex > 0 ? currentIndex - 1 : itemList.length - 1;

    setPrevIndex(_prevIndex);
    setNextIndex(_nextIndex);

    if (autoMode && !isAnimating) {
      setTimeout(() => {
        callIndex(_nextIndex);
      }, intervalTime);
    }
  }, [currentIndex, autoMode, isAnimating]);

  return (
    <div className={`${styles.carousel} ${className}`} style={{ ...style }}>
      <div className={`${styles.controls} ${!showControls ? "hidden" : ""}`}>
        <button
          className={`${
            hideArrows || (!infiniteMode && currentIndex === 0) ? "hidden" : ""
          }`}
          type="button"
          onClick={() => callIndex(prevIndex, true)}
        >
          {"<"}
        </button>

        {!hideDots &&
          itemList.map((_, index) => (
            <button
              key={index}
              onClick={() => {
                callIndex(index, true);
              }}
              className={`${styles.itemDot} ${
                index === selectedIndex ? styles.currentDot : ""
              }`}
            />
          ))}

        <button
          className={`${
            hideArrows ||
            (!infiniteMode && currentIndex === itemList.length - 1)
              ? "hidden"
              : ""
          }`}
          type="button"
          onClick={() => callIndex(nextIndex, true)}
        >
          {">"}
        </button>
      </div>

      <div
        className={styles.content}
        style={{
          "--animationBaseDuration": `${animationTime}ms`,
          "--animationBaseDelay": `${animationDelayTime}ms`,
        }}
      >
        <div
          className={`${styles.prevContent} ${
            fadeTransition ? "hidden" : styles.slideLeft
          }`}
          ref={prevItemRef}
        >
          {itemList[prevIndex]}
        </div>
        <div
          className={`${styles.currentContent} ${styles.transitioning}`}
          ref={currentItemRef}
        >
          {itemList[currentIndex]}
        </div>
        <div
          className={`${styles.nextContent} ${
            fadeTransition ? "hidden" : styles.slideRight
          }`}
          ref={nextItemRef}
        >
          {itemList[nextIndex]}
        </div>
      </div>
    </div>
  );
};
