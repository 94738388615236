import React from 'react';
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { popUpOpenState } from "../shared/state";
import AiBanner from "./AiBanner";
import { NavMenu } from "./NavMenu";
import { PyyneLogo } from "./PyyneLogo";

const Header = ({ scrollTop, logoChangeThreshold, mobileMode }) => {
  const popUpOpen = useRecoilValue(popUpOpenState);
  return (
    <header
      id="mainHeader"
      style={
        scrollTop > logoChangeThreshold
          ? {
            paddingRight: "1.45rem",
          }
          : undefined
      }
    >
      <AiBanner />
      <Link to="/">
        <PyyneLogo
          size={scrollTop > logoChangeThreshold ? "3rem" : "1.2rem"}
          showText
          textSize={mobileMode ? "0.9em" : "1.2em"}
          noAnimation
          classText={scrollTop > logoChangeThreshold ? "hidden" : ""}
        />
      </Link>
      {!popUpOpen && <NavMenu />}
    </header>
  )
};

export default Header;