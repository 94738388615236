import { useRecoilValue } from "recoil";
import {
  ClientsBanner,
  PyySection,
  PyyneLogo,
  ImagesPanel,
  PersonCard,
  ContentSoftLoad,
} from "../components";
import { mobileModeState, totalClientsShowState } from "../shared/state";
import styles from "./about.module.scss";

const leadershipList = [
  {
    name: "Björn Rosander",
    role: "Founder & CEO",
    imageName: "pyyne-bjorn",
  },
  {
    name: "Par Renyard",
    role: "Chairman & Founding Partner",
    imageName: "pyyne-par",
  },
  {
    name: "Christopher Enckell",
    role: "Partner",
    imageName: "pyyne-chris",
  },
  {
    name: "Martin Wennerberg",
    role: "Partner",
    imageName: "pyyne-martin",
  },
  {
    name: "Olof Wallentinsson",
    role: "Partner",
    imageName: "pyyne-olof",
  },
  {
    name: "André Carrara",
    role: "Country Manager, Brazil",
    imageName: "pyyne-andre",
  },
];

const investorsList = [
  {
    name: "Andreas Ehn",
    role: "Partner at Approach, former CTO at Spotify",
    imageName: "pyyne-andreas",
  },
  {
    name: "Lisa Enckell",
    role: "Partner, Approach & Antler, former VP of Marketing at Wrapp",
    imageName: "pyyne-lisa",
  },
  {
    name: "Gary Benerofe",
    role: "GP, Mu Ventures, former CEO of Mediaspa",
    imageName: "pyyne-gary2",
  },
  {
    name: "Anders Larsson",
    role: "Founding Partner TGV 4+, Founder of Netlight Consulting",
    imageName: "pyyne-anders",
  },
  // {
  //   name: "Alejandro Gonzalez Sole",
  //   role: "Software Engineering Manager, Amazon, former Attentive, ASAPP & AppNexus",
  //   imageName: "pyyne-alejandro2",
  // },
  {
    name: "Birgitta Elfversson",
    role: "Advisor, former Global Head of M&A at Unilever, Associate Principal at McKinsey",
    imageName: "pyyne-birgitta",
  },
  {
    name: "Dr. Alexander Stage",
    role: "Advisor, CDO at GLS, former Partner Technology at Global Founders Capital",
    imageName: "pyyne-alexander",
  },
];

export const About = () => {
  const totalClientsShow = useRecoilValue(totalClientsShowState);
  // const mobileMode = useRecoilValue(mobileModeState);
  return (
    <>
      <PyySection className={styles.textBlock} sectionTitle="About us">
        {/* <ContentSoftLoad anchorMultiplier={0}> */}
        <h3>Exceptional builders with a deep passion for technology</h3>

        <p>
          Engineers are artists capable of exceptional innovation. PYYNE Digital
          was founded on the belief that each one of these artists has a
          superpower ready to further the world’s most interesting innovations.
        </p>

        <p>
          Our mission is to find and nurture these talented humans and their
          superpowers by unleashing them on the challenges found within the
          world’s most innovative engineering teams.
        </p>
        {/* </ContentSoftLoad> */}
      </PyySection>

      {/* <ContentSoftLoad
        anchorMultiplier={0.5}
        className={styles.clientsContainer}
      > */}
      <PyySection className={styles.clientsContainer} softLoad>
        <ClientsBanner numActiveItems={totalClientsShow} />
      </PyySection>
      {/* </ContentSoftLoad> */}

      {/* <ContentSoftLoad className={styles.logoContainer}> */}
      <PyySection
        className={styles.logoContainer}
        animateFirstLevel={true}
        bannerMode
        softLoad
      >
        <PyyneLogo size="25vmin" />
      </PyySection>
      {/* </ContentSoftLoad> */}

      <PyySection
        className={styles.textBlock}
        sectionTitle="Our story"
        softLoad
      >
        {/* <ContentSoftLoad anchorMultiplier={5.5}> */}
        <h3>We believe engineers are unique artists and not a commodity</h3>

        <p>
          We recognize that engineers bring a unique perspective and set of
          skills to the table. At PYYNE Digital, we've built a team of skilled
          professionals who are not only talented problem-solvers, but
          passionate creators. Our team works together to deliver exceptional
          results and help our clients solve their greatest engineering
          challenges.
        </p>
        {/* </ContentSoftLoad> */}
      </PyySection>

      <PyySection
        sectionTitle="LEADERSHIP"
        style={{ marginTop: "10vh" }}
        softLoad
      >
        {/* <ContentSoftLoad scrollAnchor={1350}> */}
        <ImagesPanel
          itemsList={leadershipList.map((item, index) => (
            <PersonCard
              title={item.name}
              subtitle={item.role}
              imageName={item.imageName}
              imageExtension="jpeg"
              style={index === 1 && { transform: "scale(1.02)" }}
            />
          ))}
          noAnimation
        />
        {/* </ContentSoftLoad> */}
      </PyySection>

      <PyySection
        style={{ margin: "10vh 0" }}
        sectionTitle="INVESTORS & ADVISORS"
        softLoad
      >
        <ImagesPanel
          itemsList={investorsList.map((item) => (
            <PersonCard
              title={item.name}
              subtitle={item.role}
              imageName={item.imageName}
              imageExtension="jpeg"
            />
          ))}
          noAnimation
        />
      </PyySection>

      <PyySection fullWidth className={styles.bannerText} softLoad>
        <h3>Want to work with us or join our team? </h3>

        <p>
          Are you looking for a team of engineers, or do you want to join a
          global engineering network and work with some of the coolest products
          out there?{" "}
        </p>

        <div>
          <a href="mailto:hello@pyyne.com">Work with us?</a>

          <a href="mailto:talent@pyyne.com">Join our team?</a>
        </div>
      </PyySection>
    </>
  );
};
