import styles from "./PersonCard.module.scss";

export const PersonCard = ({
  style,
  className,
  title,
  subtitle,
  ImageNode,
  hideText,
  imageName,
  imageExtension,
}) => {
  imageExtension = imageExtension || "png";

  return (
    <span
      className={`${styles.personCard} ${className || ""}`}
      style={{ ...style }}
    >
      <span className={styles.imgContainer}>
        {ImageNode ? (
          <ImageNode />
        ) : imageName ? (
          <img
            alt="Person image"
            src={require(`../assets/images/people/${imageName}.${imageExtension}`)}
          />
        ) : (
          <></>
        )}
      </span>

      {!hideText && (
        <span className={styles.infoContainer}>
          <h5>{title}</h5>
          <p>{subtitle}</p>
        </span>
      )}
    </span>
  );
};
