import styles from "./PyyneLogo.module.scss";

export const PyyneLogo = ({
  noAnimation,
  duration,
  size,
  showText = false,
  noAnimatedText,
  durationText,
  textSize,
  onClick,
  className,
  classText,
  style,
}) => {
  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    }
  };
  return (
    <div
      className={`${styles.logoContainer} ${className ? className : ""}`}
      style={{
        "--size": size,
        "--duration": noAnimation ? 0 : duration,
        "--startScale": noAnimation ? 1 : 0.1,
        "--startTop": noAnimation ? "20%" : "5%",
        "--startPosition": noAnimation ? 0 : "2rem",
        "--startMaxWidth": noAnimation ? "auto" : 0,
        ...style,
      }}
      onClick={handleClick}
    >
      <div className={styles.topHalf}>
        <span className={styles.leftSide} />
        <span className={styles.rightSide} />
      </div>

      <div className={styles.bottomHalf}>
        <span className={styles.leftSide} />
        <span className={styles.rightSide} />
      </div>

      {showText && (
        <p
          className={classText ? classText : ""}
          style={{
            "--durationText": noAnimatedText ? "0s" : durationText,
            fontSize: textSize,
          }}
        >
          PYYNE DIGITAL
        </p>
      )}
    </div>
  );
};
