import { useRecoilValue } from "recoil";
import {
  ClientsBanner,
  ContentSoftLoad,
  ProjectCard,
  PyySection,
} from "../components";
import { totalClientsShowState } from "../shared/state";

import styles from "./work.module.scss";
import { useCasesList } from "../shared/hooks";

export const Work = () => {
  const totalClientsShow = useRecoilValue(totalClientsShowState);
  const { preparedCasesList, projectCardWidth, dualColProjectCardWidth } =
    useCasesList();

  return (
    <>
      <PyySection sectionTitle="Work" />

      {preparedCasesList.map((caseRow, rowIndex, rowList) => (
        <PyySection
          key={`row${rowIndex}`}
          dualMode
          className={styles.projectCardsContainer}
        >
          {caseRow.map((clientCase, index, clientList) => (
            <ContentSoftLoad
              key={`row${rowIndex}.client${index}`}
              animationType="grow"
            >
              <ProjectCard
                {...clientCase}
                width={
                  rowIndex === rowList.length - 1 && clientList.length === 1
                    ? projectCardWidth
                    : dualColProjectCardWidth
                }
              />
            </ContentSoftLoad>
          ))}
        </PyySection>
      ))}

      <PyySection
        sectionTitle={
          <span style={{ marginBottom: "1rem" }}>Our amazing clients</span>
        }
        className={styles.clientsContainer}
        softLoad
      >
        <ClientsBanner numActiveItems={1000} />
      </PyySection>
    </>
  );
};
