export const CASES_LIST = [
  {
    title: "Inveterate",
    subtitle: "Platform Engineering",
    teamType: "Hybrid Team",
    officesList: ["US", "Brazil"],
    skillsList: ["Fullstack", "Backend", "PM"],
    usedStack: [
      "Node",
      "React",
      "Serverless",
      "AWS",
      "CDK",
      "SQS",
      "SNS",
      "Event Bridge",
      "Step Functions",
      "DynamoDB +5",
    ],
    imgName: "InveterateCase",
    featured: true,
    order: 1,
  },
  {
    title: "Pack Digital",
    subtitle: "Self-service Storefront Launch, Platform Engineering",
    teamType: "Hybrid Team",
    officesList: ["US", "Brazil"],
    skillsList: ["Fullstack", "DevOps"],
    usedStack: [
      "Node",
      "React",
      "Github Actions",
      "Kubernetes",
      "Terraform",
      "Helm",
      "Docker",
      "AWS +4",
    ],
    imgName: "PackCase",
    featured: true,
  },
  {
    title: "The Meet Group",
    subtitle:
      "Reusable Web Components, Data Platform, Content Moderation & Backend",
    teamType: "Nearshore Teams",
    officesList: ["Brazil"],
    skillsList: ["Frontend", "Backend", "Data"],
    usedStack: [
      "ReactJS",
      "Redux",
      "Storybook",
      "SASS",
      "Mantine",
      "AWS",
      "Node",
      "Java",
      "Kafka",
      "Spark",
      "Linux",
      "SQL",
      "Databricks +5",
    ],
    imgName: "TMGCase",
    maxStackNumber: 13,
    featured: true,
  },
  {
    title: "Landscape Hub",
    subtitle: "Platform & Data Engineering",
    teamType: "Hybrid Team",
    officesList: ["US", "Brazil"],
    skillsList: ["Fullstack", "DevOps", "Data"],
    usedStack: [
      "Python",
      "Terraform",
      "Redshift",
      "Airflow",
      "AWS",
      "DataDog",
      "CircleCI +4",
    ],
    imgName: "LandscapehubCase",
    featured: true,
  },
];
