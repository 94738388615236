export const saveSessionStorage = (key, value, isObject) => {
  const savedValue = isObject ? JSON.stringify(value) : value;

  sessionStorage.setItem(key, savedValue);
};

export const getSessionStorage = (key, isObject) => {
  const loadedValue = sessionStorage.getItem(key);

  return isObject ? JSON.parse(loadedValue) : loadedValue;
};

export const saveLocalStorage = (key, value, isObject) => {
  const savedValue = isObject ? JSON.stringify(value) : value;

  localStorage.setItem(key, savedValue);
};

export const getLocalStorage = (key, isObject) => {
  const loadedValue = localStorage.getItem(key);

  return isObject ? JSON.parse(loadedValue) : loadedValue;
};

export const cloneObject = (obj) => JSON.parse(JSON.stringify(obj));

export const isEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const randomNumber = (minNum, maxNum, includeMax = false) => {
  const inclusiveOffset = includeMax ? 1 : 0;

  return Math.floor(
    Math.random() * (maxNum - minNum + inclusiveOffset) + minNum
  );
};