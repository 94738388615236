import { atom } from "recoil";
import { NAMESPACE } from "./constants";

export const themeState = atom({
  key: `${NAMESPACE}theme`,
  default: "mainTheme",
});

export const showMenuState = atom({
  key: `${NAMESPACE}showMenu`,
  default: false,
});

export const scrollTopState = atom({
  key: `${NAMESPACE}scrollTop`,
  default: 0,
});

export const totalClientsShowState = atom({
  key: `${NAMESPACE}totalClientsShow`,
  default: 10,
});

export const mobileModeState = atom({
  key: `${NAMESPACE}mobileMode`,
  default: false,
});

export const popUpOpenState = atom({
  key: `${NAMESPACE}popUpOpen`,
  default: false,
});

export const bannerShowState = atom({
  key: `${NAMESPACE}bannerShow`,
  default: true,
});