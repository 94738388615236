import React from "react";
import { randomNumber } from "../helpers";
import styles from "./ImagesPanel.module.scss";

export const ImagesPanel = ({
  style,
  className,
  itemsList = [],
  totalRowItems,
  totalActiveItems,
  noAnimation,
  hideItemText,
}) => {
  totalActiveItems = totalActiveItems ? totalActiveItems : Object.keys(itemsList).length;
  const [activeKeys, setActiveKeys] = React.useState([]);
  const [panelId, setPanelId] = React.useState(0);

  const drawActiveKeys = () => {
    const possibleKeys = Object.keys(itemsList);

    const _activeKeys = [];

    while (_activeKeys.length < totalActiveItems) {
      const drawnIndex = randomNumber(0, possibleKeys.length);
      const [selectedKey] = possibleKeys.splice(drawnIndex, 1);

      _activeKeys.push(selectedKey);
    }

    setActiveKeys(_activeKeys);
  }

  React.useEffect(() => {
    setPanelId(randomNumber(0, 100000, true));
  }, []);

  React.useEffect(() => {
    if (activeKeys.length === 0) {
      if (totalActiveItems === itemsList.length) {
        setActiveKeys(Object.keys(itemsList));
      } else {
        drawActiveKeys();
      }
    }
  }, [activeKeys]);

  return (<div className={`${styles.imagesPanel} ${className || ""} ${!noAnimation && totalActiveItems < itemsList.length ? styles.animatedPanel : ""}`} style={{ ...style, "--totalRowItems": totalRowItems, "--totalItems": totalActiveItems }}>
    {activeKeys?.length > 0 && <ul>
      {activeKeys.map((itemKey, index) => <li id={`item#${panelId}#${index}`} key={index}>
        {React.cloneElement(itemsList[itemKey], { hideText: hideItemText })}
      </li>)}
    </ul>}
  </div>);
};