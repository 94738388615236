import React from "react";
import { useRecoilValue } from "recoil";
import { mobileModeState } from "../state";
import { CASES_LIST } from "../clients";

export const useCasesList = (options) => {
  const {
    onlyFeatured = false,
    crescentOrder = true,
    maxItems,
  } = options || {};
  const mobileMode = useRecoilValue(mobileModeState);

  const [projectCardWidth, dualColProjectCardWidth] = React.useMemo(
    () => (mobileMode ? [] : ["90%", "100%"]),
    [mobileMode]
  );

  const casesList = React.useMemo(() => {
    const bruteList = CASES_LIST.map((clientCase, index) => ({
      ...clientCase,
      order: clientCase.order || index + 1,
    }));
    const filterredList = !onlyFeatured
      ? bruteList
      : bruteList.filter((item) => item.featured);

    if (crescentOrder) {
      filterredList.sort((a, b) => {
        return a.order - b.order;
      });
    }

    return !maxItems || maxItems < 0
      ? filterredList
      : filterredList.splice(0, maxItems);
  }, [CASES_LIST, onlyFeatured, crescentOrder, maxItems]);

  const preparedCasesList = React.useMemo(() => {
    const preparedList = [];

    casesList.forEach((clientCase, index) => {
      if (index % 2 === 0) {
        preparedList.push([clientCase]);
      } else {
        preparedList[preparedList.length - 1].push(clientCase);
      }
    });

    return preparedList;
  }, [casesList]);

  return React.useMemo(
    () => ({
      casesList,
      preparedCasesList,
      projectCardWidth,
      dualColProjectCardWidth,
    }),
    [casesList, preparedCasesList, projectCardWidth, dualColProjectCardWidth]
  );
};
