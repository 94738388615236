import React from "react";
import { Link } from "react-router-dom";
import {
  ImgLogoArtfare,
  ImgLogoDynamicMining,
  ImgLogoFabric,
  ImgLogoInveterate,
  ImgLogoLyfta,
  ImgLogoPackDigital,
  ImgLogoRever,
  ImgLogoSas,
  ImgLogoSpotify,
  ImgLogoStarwood,
  ImgLogoTibber,
  ImgLogoTmg,
  ImgVetFamily,
} from "../assets/images";
import { randomNumber } from "../helpers";
import styles from "./ClientsBanner.module.scss";

const DURATION = {
  ANIMATION: 2500,
};

export const ClientsBanner = ({
  style,
  className,
  showClientsLink,
  numActiveItems = 8,
}) => {
  const clientsObject = {
    artfare: { logo: <ImgLogoArtfare />, reserved: false },
    dynamicMining: { logo: <ImgLogoDynamicMining />, reserved: false },
    fabric: { logo: <ImgLogoFabric />, reserved: false },
    inveterate: { logo: <ImgLogoInveterate />, reserved: false },
    lyfta: { logo: <ImgLogoLyfta />, reserved: false },
    packDigital: { logo: <ImgLogoPackDigital />, reserved: false },
    rever: { logo: <ImgLogoRever />, reserved: false },
    sas: { logo: <ImgLogoSas />, reserved: false },
    spotify: { logo: <ImgLogoSpotify />, reserved: false },
    starwood: { logo: <ImgLogoStarwood />, reserved: false },
    tibber: { logo: <ImgLogoTibber />, reserved: false },
    vetfamily: { logo: <ImgVetFamily />, reserved: false },
    tmg: { logo: <ImgLogoTmg />, reserved: false },
  };
  const [activeNames, setActiveNames] = React.useState([]);

  const getNextClientName = (possibleClients) => {
    possibleClients =
      possibleClients?.length > 0
        ? possibleClients
        : Object.keys(clientsObject);
    const nextClientIndex = randomNumber(0, possibleClients.length);
    const [nextClientName] = possibleClients.splice(nextClientIndex, 1);

    if (!activeNames.includes(nextClientName)) {
      clientsObject[nextClientName].reserved = true;
      return nextClientName;
    }

    return getNextClientName(possibleClients);
  };

  const changeLogo = (logoContainerIndex, duration) => {
    duration = duration
      ? duration
      : randomNumber(0, DURATION.ANIMATION / 2, true);
    logoContainerIndex = logoContainerIndex
      ? logoContainerIndex
      : randomNumber(0, activeNames.length);
    const logoContainerDOM = document.getElementById(
      `container${logoContainerIndex}`
    );
    const currentLogoName = activeNames[logoContainerIndex];
    const nextLogoName = getNextClientName();
    clientsObject[currentLogoName].reserved = false;

    logoContainerDOM.classList.add("hidden");

    setTimeout(() => {
      setActiveNames(
        activeNames.map((name, index) =>
          index !== logoContainerIndex ? name : nextLogoName
        )
      );

      logoContainerDOM.classList.remove("hidden");
    }, DURATION.ANIMATION + duration);
  };

  React.useEffect(() => {
    const possibleClients = Object.keys(clientsObject);
    const _activeNames = [...activeNames];

    if (_activeNames.length !== numActiveItems) {
      while (
        _activeNames.length < numActiveItems &&
        possibleClients.length > 0
      ) {
        const drawnIndex = randomNumber(0, possibleClients.length);

        const [clientName] = possibleClients.splice(drawnIndex, 1);

        if (!_activeNames.includes(clientName)) {
          _activeNames.push(clientName);
        }
      }

      if (_activeNames.length > numActiveItems) {
        _activeNames.splice(numActiveItems);
      }

      setActiveNames(_activeNames);
    }
  }, [numActiveItems]);

  React.useEffect(() => {
    if (activeNames?.length === numActiveItems) {
      changeLogo();
    }
  }, [activeNames]);

  return (
    <div
      className={`${styles.clientsBanner} ${className || ""}`}
      style={{ ...style }}
    >
      <ul>
        {activeNames?.map((clientName, index) => (
          <li id={`container${index}`} key={`client${index}`}>
            {clientsObject[clientName]?.logo || (
              <img
                src={require(`../assets/images/${clientsObject[clientName]?.logoURL}`)}
                alt={`${clientName} logo`}
              />
            )}
          </li>
        ))}
      </ul>
      {showClientsLink && <Link to="/clients">View all clients</Link>}
    </div>
  );
};
