import { Routes as RoutesDOM, Route } from "react-router-dom";
import { About } from "./about";
import { Clients } from "./clients";
import { Home } from "./home";
import { Work } from "./work";

export const Routes = () => {
  return (
    <RoutesDOM>
      <Route path="/" exact element={<Home />} />
      <Route path="/clients" exact element={<Clients />} />
      <Route path="/work" exact element={<Work />} />
      <Route path="/about" exact element={<About />} />
    </RoutesDOM>
  );
};
